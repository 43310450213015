


import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import config from '../config/config'
import Web3 from 'web3';
import Swal from 'sweetalert2'
import axios from 'axios'

const ABI = config.royaltyABI;
const Contract = config.royaltyContract;
const apiUrl = 'https://nft.bootycoinnft.com/api-royalty/';// 'https://api.oddfellowsnft.com/api-royalty/';

const RewardMenu = (props) => {


    const [totalReward, settotalReward] = useState(0);
    const [isOnwer, setIsOnwer] = useState(false);
    const [RewardBtnText, setRewardBtnText] = useState('Withdraw');
    const [RoyaltyBtnText, setRoyaltyBtnText] = useState('Royalty Distribute');
    const [ETHinUSD, setETHinUSD] = useState(0);
    const [communityWalletBalance, setcommunityWalletBalance] = useState(0);
    const [isActiveTab, setisActiveTab] = useState('Weekly');

    const [weeklyTrx, setWeeklyTrx] = useState([]);
    const [monthlyTrx, setMonthlyTrx] = useState([]);
    const [yearlyTrx, setYearlyTrx] = useState([]);
    const [SendBtnText,setSendBtnText]= useState('');


    useEffect(() => {
        getWalletDetail();
        getCommunityWalletDetail();
        getLivePrice();
        getRandomRewards();
    }, [])

    const getLivePrice = async () => {
        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,

        }).then(response => {
            setETHinUSD(parseFloat(response.data.price).toFixed(4));
        })
    }

    const getWalletDetail = async () => {

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');


        const response = await getClaimDetailsAPI(fromAddress);
        let myReward = parseFloat(response.amount);

        settotalReward(myReward);

        const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);
        let contractOnwer = await Royalty_CONTRACT.methods.owner().call();
        if (fromAddress.toUpperCase() == contractOnwer.toUpperCase()) {
            setIsOnwer(true);
        }
    }

    const getClaimDetailsAPI = async (address) => {
        const res = await axios({
            method: 'post',
            url: `${apiUrl}getclaimAmount`,
            data: { address }
        });
        return {
            status: res.data.status,
            amount: (!res.data.result.claimAmount) ? 0 : res.data.result.claimAmount,
            hexroot_id: (!res.data.result.hexroot_id) ? 0 : res.data.result.hexroot_id,
            hexproof: (!res.data.hexproof) ? [] : res.data.hexproof,
        }
    }

    const getRandomRewards = async () => {
        const res = await axios({
            method: 'get',
            url: `${apiUrl}getRandomReward`,
        });
        if (res.data.status) {
            setWeeklyTrx(res.data.weekly);
            setYearlyTrx(res.data.yearly);
            setMonthlyTrx(res.data.monthly);
        }
    }

    const getCommunityWalletDetail = async () => {
        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let balance = await web3.eth.getBalance(Contract);

        balance = (parseFloat(parseInt(balance) / 10 ** 18) / 2).toFixed(6);
        setcommunityWalletBalance(!balance ? 0.00 : balance);
    }

    const getCurrentProvider = async () => {
        let CurrentProvider = "";
        if (localStorage.getItem('connectWalletAddress') && localStorage.getItem("walletType") == 'metamask') {
            if (!window.ethereum.providers) {
                CurrentProvider = await window.ethereum;
            } else {
                CurrentProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
            }
        } else {
            if (!window.ethereum.providers) {
                if (window.ethereum.isCoinbaseWallet == true) {
                    CurrentProvider = await window.ethereum;
                }
            } else {
                CurrentProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
            }
        }
        return CurrentProvider;
    }


    const claimReward = async () => {

        if (RewardBtnText === "Processing..." || totalReward == 0) {
            return;
        }

        // Swal.fire({
        //     text: 'Withdraw Functionality Will Open On February, 5th 2022',
        //     // icon: 'warning',
        //     title: "Notice!",
        //     confirmButtonText: 'Close',
        //     confirmButtonColor: '#ae7d5c',
        //     background: '#311a12',
        //     color: '#fff',
        //     width:'550px'
        // })




        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');
        web3.eth.defaultAccount = fromAddress;

        try {
            const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);

            if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {
                Swal.fire({
                    text: 'Please select Ethereum network!',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
                return;
            }
            setRewardBtnText('Processing...');

            const response = await getClaimDetailsAPI(fromAddress);
            if (parseFloat(response.amount) == 0) {
                setRewardBtnText('Withdraw');

                Swal.fire({
                    text: 'You don\'t have reward amount!',
                    // icon: 'warning',
                    title: "Notice!",
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff',
                    width: '550px'
                });
                return;
            }
            let amount = parseInt(parseFloat(response.amount) * 10 ** 18).toString();
            console.log(response);

            let chainId = '0x1';
            let tx_builder = await Royalty_CONTRACT.methods.claimReward(amount, response.hexproof);
            let encoded_tx = tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 10000000000;

            let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: Contract,
                from: fromAddress,
                chainId: chainId,
                data: encoded_tx
            });
            const transactionParameters = {
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: Contract,
                chainId: chainId,
                data: encoded_tx
            };

            // const txHash = await web3.eth.sendTransaction(transactionParameters);
            const txHash = await web3.currentProvider.send("eth_sendTransaction", [transactionParameters]);
            if (txHash.result) {
                const res = await axios({
                    method: 'post',
                    url: `${apiUrl}updateClaimAmount`,
                    data: {
                        address: fromAddress,
                        amount: response.amount,
                        hash: txHash.result,
                        hexroot_id: response.hexroot_id
                    }
                });

                Swal.fire({
                    width: 500,
                    title: 'Successful!',
                    html: 'Congratulation! Reward Claimed Successfully.',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Ok, Thanks',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                }).then((result) => {
                    window.location.reload();
                })
            }
        } catch (err) {
            console.log("err", err)
            setRewardBtnText('Withdraw');

            if (err.message.toString().split('insufficient funds')[1]) {

                Swal.fire({
                    text: 'Transaction reverted : ' + err.message,
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
            } else {
                if (err.toString().split('execution reverted:')[1]) {
                    Swal.fire({
                        text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
                        // icon: 'warning',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#ae7d5c',
                        background: '#311a12',
                        color: '#fff'
                    })
                } else {
                    if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
                        Swal.fire({
                            text: "Transaction reverted : insufficient funds for transaction fee",
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    } else {
                        Swal.fire({
                            text: err.message,
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    }
                }
            }
        }

    }

    const transferRandomReward = async (item) => {

        if (SendBtnText === "Processing..." || item.amount == 0) {
            return;
        }

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');
        web3.eth.defaultAccount = fromAddress;

        try {
            const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);

            if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {
                Swal.fire({
                    text: 'Please select Ethereum network!',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
                return;
            }
            setSendBtnText('Processing...');


            let amount = parseInt(parseFloat(item.amount) * 10 ** 18).toString();

            let chainId = '0x1';
            let tx_builder = '';

            if (item.type == 1) {
                tx_builder = await Royalty_CONTRACT.methods.WeeklyReward(item.address,amount);
            } else if (item.type == 2) {
                tx_builder = await Royalty_CONTRACT.methods.MonthlyReward(item.address,amount);
            } else if (item.type == 3) {
                tx_builder = await Royalty_CONTRACT.methods.YearlyReward(item.address,amount);
            }

            let encoded_tx = tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 10000000000;

            let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: Contract,
                from: fromAddress,
                chainId: chainId,
                data: encoded_tx
            });
            const transactionParameters = {
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: Contract,
                chainId: chainId,
                data: encoded_tx
            };

            // const txHash = await web3.eth.sendTransaction(transactionParameters);
            const txHash = await web3.currentProvider.send("eth_sendTransaction", [transactionParameters]);
            if (txHash.result) {
                const res = await axios({
                    method: 'post',
                    url: `${apiUrl}updateRandomReward`,
                    data: {
                        id: item.id,
                        hash: txHash.result,
                    }
                });

                Swal.fire({
                    width: 500,
                    title: 'Successful!',
                    html: 'Congratulation! Reward Transferred Successfully.',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Ok, Thanks',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                }).then((result) => {
                    window.location.reload();
                })
            }
        } catch (err) {
            console.log("err", err)
            setSendBtnText('Send');

            if (err.message.toString().split('insufficient funds')[1]) {

                Swal.fire({
                    text: 'Transaction reverted : ' + err.message,
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
            } else {
                if (err.toString().split('execution reverted:')[1]) {
                    Swal.fire({
                        text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
                        // icon: 'warning',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#ae7d5c',
                        background: '#311a12',
                        color: '#fff'
                    })
                } else {
                    if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
                        Swal.fire({
                            text: "Transaction reverted : insufficient funds for transaction fee",
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    } else {
                        Swal.fire({
                            text: err.message,
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    }
                }
            }
        }
    }


    //  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


    const royaltyDistribute = async () => {

        if (RoyaltyBtnText === "Processing...") {
            return;
        }

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');
        web3.eth.defaultAccount = fromAddress;

        try {
            const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);

            if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {
                Swal.fire({
                    text: 'Please select Ethereum network!',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
                return;
            }
            setRoyaltyBtnText('Processing...');

            const response = await axios({
                method: 'get',
                url: `${apiUrl}royaltyDistribution`,
            });
            if (!response.data.hexroot) {
                setRoyaltyBtnText('Royalty Distribute');
                Swal.fire({
                    text: response.data.msg,
                    // icon: 'warning',
                    title: "Notice!",
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff',
                    width: '550px'
                });
                return;
            }

            let chainId = '0x1';
            let tx_builder = await Royalty_CONTRACT.methods.setRoot(response.data.hexroot);
            let encoded_tx = tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 10000000000;

            let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: Contract,
                from: fromAddress,
                chainId: chainId,
                data: encoded_tx
            });
            const transactionParameters = {
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: Contract,
                chainId: chainId,
                data: encoded_tx
            };

            // const txHash = await web3.eth.sendTransaction(transactionParameters);
            const txHash = await web3.currentProvider.send("eth_sendTransaction", [transactionParameters]);
            if (txHash.result) {
                const res = await axios({
                    method: 'post',
                    url: `${apiUrl}confirmRoyaltyDistribution`,
                    data: {
                        hash: txHash.result,
                        id: response.data.id
                    }
                });

                Swal.fire({
                    width: 500,
                    title: 'Successful!',
                    html: 'Congratulation! Reward Claimed Successfully.',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Ok, Thanks',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                }).then((result) => {
                    window.location.reload();
                })
            }
        } catch (err) {
            console.log("err", err)
            setRoyaltyBtnText('Royalty Distribute');

            if (err.message.toString().split('insufficient funds')[1]) {

                Swal.fire({
                    text: 'Transaction reverted : ' + err.message,
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
            } else {
                if (err.toString().split('execution reverted:')[1]) {
                    Swal.fire({
                        text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
                        // icon: 'warning',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#ae7d5c',
                        background: '#311a12',
                        color: '#fff'
                    })
                } else {
                    if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
                        Swal.fire({
                            text: "Transaction reverted : insufficient funds for transaction fee",
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    } else {
                        Swal.fire({
                            text: err.message,
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    }
                }
            }
        }

    }

    //  -----------------------------------------------------------------

    // const getRewardAmount = async () => {

    //     const providers = await getCurrentProvider();
    //     const web3 = new Web3(providers);
    //     let fromAddress = localStorage.getItem('connectWalletAddress');
    //     const NFT_CONTRACT = await new web3.eth.Contract(config.ABI, config.contractAddress);

    //     let halfAmount = 0.07677949309;
    //     let totalSupply = await NFT_CONTRACT.methods.totalSupply().call();
    //     let newAmount = halfAmount / parseInt(totalSupply);
    //     let myReward = 0;

    //     let myNFT = await NFT_CONTRACT.methods.balanceOf(fromAddress).call();
    //     myReward = myNFT * newAmount;
    //     settotalReward(!myReward ? 0.00 : myReward);
    // }



    return (

        <>
            {localStorage.getItem('connectWalletAddress') ?
                <div className='after_connect_wallet'>
                    <div>
                        <div className='wallet_address'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label>Community Wallet</label>

                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <a href="https://etherscan.io/address/0x9015B13383bf7C7064a7cfE9aCa88F2aBE118341" target="_blank" className='address'>0x901...18341</a>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <h6 className='text-white text-center'>{parseFloat(communityWalletBalance).toFixed(4)} ETH  ( $ {parseFloat(communityWalletBalance * ETHinUSD).toFixed(2)} )</h6>
                                    <hr />
                                    {/* <div className='col-md-6 col-6'>
                                        <h6 className='text-white'>{parseFloat(communityWalletBalance).toFixed(4)} ETH</h6>
                                    </div>
                                    <div className='col-md-6 col-6'>
                                        <h6 className='text-white'>$ {parseFloat(communityWalletBalance * ETHinUSD).toFixed(2)}</h6>
                                    </div> */}
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label>My Wallet</label>

                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <a href={`https://etherscan.io/address/${localStorage.getItem('connectWalletAddress')}`} target="_blank" className='address'>{localStorage.getItem('connectWalletAddress').toString().substring(0, 5) + '...' + localStorage.getItem('connectWalletAddress').toString().substr(localStorage.getItem('connectWalletAddress').length - 5)}</a>
                                </div>
                            </div>

                        </div>



                        <div className='show_balance'>
                            <p>Royalty Balance</p>
                            <div className='row'>
                                <div className='col-md-7 col-7'>
                                    <h4 className='text-white'>{parseFloat(totalReward).toFixed(8)} ETH</h4>
                                </div>
                                <div className='col-md-5 col-5'>
                                    <h4 className='text-white'>$ {parseFloat(totalReward * ETHinUSD).toFixed(2)}</h4>
                                </div>
                            </div>
                            {/* <p style={{}}>Note : </p> */}
                            <button className='btn btn-primary add_fund_btn' disabled={totalReward == 0} type='button' onClick={e => claimReward()}> {RewardBtnText}</button>
                        </div>
                        {isOnwer ?
                            <>
                                <div className='show_balance' style={{ marginTop: '10px' }}>
                                    {/* <hr/> */}
                                    {/* <p style={{}}>Note : </p> */}
                                    <p>Royalty Distribution BY Owner</p>
                                    <button className='btn btn-primary add_fund_btn' type='button' onClick={e => royaltyDistribute()}> {RoyaltyBtnText}</button>
                                </div>
                                <div className='row mt-4 text-center'>
                                    <div className='col-sm-4'>
                                        <button className={`btn btntabs ${isActiveTab == 'Weekly' ? 'active' : ''}`} onClick={e => setisActiveTab('Weekly')}>Weekly</button>

                                    </div>
                                    <div className='col-sm-4'>
                                        <button className={`btn btntabs ${isActiveTab == 'Monthly' ? 'active' : ''}`} onClick={e => setisActiveTab('Monthly')}>Monthly</button>

                                    </div>
                                    <div className='col-sm-4'>
                                        <button className={`btn btntabs ${isActiveTab == 'Yearly' ? 'active' : ''}`} onClick={e => setisActiveTab('Yearly')}>yearly</button>

                                    </div>

                                </div>
                                <div className='col-sm-12 mt-3'>
                                    <div className='tab1'>
                                        {(isActiveTab == 'Weekly' && weeklyTrx.length > 0) ?
                                            weeklyTrx.map(item => (
                                                <div class="row">
                                                    <div class="col-md-5 col-5">
                                                        <p class="text-white">{parseFloat(item.amount).toFixed(6)} ETH</p>
                                                    </div>
                                                    <div class="col-md-4 col-4">
                                                        <p class="text-white">{item.address.toString().substring(0, 5) + '...' + item.address.toString().substr(item.address.length - 5)}</p>
                                                    </div>
                                                    <div class="col-md-3 col-3 text-right">
                                                        <button style={{ padding: '5px' }} onClick={e => transferRandomReward(item)} className='btn btn-sm btn-success'>Send</button>
                                                    </div>
                                                </div>
                                            ))
                                            : (isActiveTab == 'Monthly' && monthlyTrx.length > 0) ?
                                                monthlyTrx.map(item => (
                                                    <div class="row">
                                                        <div class="col-md-5 col-5">
                                                            <p class="text-white">{parseFloat(item.amount).toFixed(6)} ETH</p>
                                                        </div>
                                                        <div class="col-md-4 col-4">
                                                            <p class="text-white">{item.address.toString().substring(0, 5) + '...' + item.address.toString().substr(item.address.length - 5)}</p>
                                                        </div>
                                                        <div class="col-md-3 col-3 text-right">
                                                            <button style={{ padding: '5px' }} onClick={e => transferRandomReward(item)} className='btn btn-sm btn-success'>Send</button>
                                                        </div>
                                                    </div>
                                                ))
                                                : (isActiveTab == 'Yearly' && yearlyTrx.length > 0) ?
                                                    yearlyTrx.map(item => (
                                                        <div class="row">
                                                            <div class="col-md-5 col-5">
                                                                <p class="text-white">{parseFloat(item.amount).toFixed(6)} ETH</p>
                                                            </div>
                                                            <div class="col-md-4 col-4">
                                                                <p class="text-white">{item.address.toString().substring(0, 5) + '...' + item.address.toString().substr(item.address.length - 5)}</p>
                                                            </div>
                                                            <div class="col-md-3 col-3 text-right">
                                                                <button style={{ padding: '5px' }} onClick={e => transferRandomReward(item)} className='btn btn-sm btn-success'>Send</button>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    'No Data Found'
                                        }
                                    </div>

                                    <br />
                                    <br />
                                </div>
                            </>
                            : ""}

                    </div>
                </div> : ''}



        </>
    );
}

export default RewardMenu;   