
import React, { useEffect, useState } from 'react';
import config from '../config/config'
import { NavLink } from 'react-router-dom';
import Fortmatic from 'fortmatic';
import Web3 from 'web3';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2'
import { Dialog, Classes } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import BarLoader from 'react-bar-loader'
import RewardMenu from '../Components/reward';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

const Header = (props) => {

  const [isConnectMetamask, setisConnectMetamask] = useState(false);
  const [navActiveData, setnavActiveData] = useState(1)
  const [ConnectWalletAddress, setConnectWalletAddress] = useState('');
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  useEffect(() => {
    // if (typeof window.ethereum !== 'undefined') {
    //   console.log('MetaMask is installed!');
    // }
    // connectMetaMask()
    setInterval(() => {


      if (window.ethereum && localStorage.getItem('isLogout') == '') {
        const { ethereum } = window;
        if (ethereum.selectedAddress) {
          var web3 = new Web3(window.ethereum);
          var currentNetwork = web3.currentProvider.chainId;
          // if (currentNetwork != '56' && currentNetwork != '0x61') {
          //     Cookies.set('loginSuccessMetaseer', "");
          //     return;
          // }
          setConnectWalletAddress(ethereum.selectedAddress)
        }
      }
    }, 1000);
  }, [])


  const connectMetaMask = async () => {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {

        Swal.fire({
          text: 'Please install MetaMask to use this App!',
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: 'rgb(1 2 2)',
          background: 'rgb(141 96 153)',
          color: '#fff'
        })
        return;
      }

      if (!window.ethereum.providers) {
        var metamaskProvider = await window.ethereum;
      } else {
        var metamaskProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
      try {
        const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0])
        setisConnectMetamask(true)
        localStorage.setItem('walletType', 'metamask')
        localStorage.setItem('connectWalletAddress', accounts[0]);
        localStorage.setItem('isLogout', "");

        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        });
        window.location.reload();
      } catch (err) {

        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: 'rgb(1 2 2)',
          background: 'rgb(141 96 153)',
          color: '#fff'
        })
      }

    }
    else {
      Swal.fire({
        text: 'Please install MetaMask to use this App!',
        // icon: 'warning',
        title: "Alert",
        confirmButtonText: 'Okay',
        confirmButtonColor: 'rgb(1 2 2)',
        background: 'rgb(141 96 153)',
        color: '#fff'
      })

    }
  }

  const logout = () => {

    localStorage.setItem('connectWalletAddress', '');
    localStorage.setItem('isLogout', "Yes");
    window.location.reload();
  }

  const navActive = (id) => {
    setnavActiveData(id)
  }



  return (

    <>

      <div className="header-fixed bg-black fixed-top box-shadow border-nav " style={{ padding: "6px ​0px" }}>
        <div className="container">
          <Toaster


            position="top-right"
            reverseOrder={false}
          />
          <div className="row">
            <div className="col-lg-3 col-3 col-sm-3 col-md-3 mr-auto">
              <a className="navbar-brand logo" href={`${config.baseUrl}`}>
                {/* <img src="assets/img/logo_bac.png" alt="Wicodus" className="logo-light mx-auto" /> */}
                <img src="assets/img/logo.jpg" />

              </a>
            </div>
            <div className="col-9 col-sm-9 col-md-9 col-lg-9 text-right">
              <nav className="navbar navbar-expand-lg  ">
                <div className="row ">
                  <button className="navbar-toggler navbar-toggler-fixed mt-5" type="button" data-toggle="collapse" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation">☰</button>
                  <div className="collapse navbar-collapse pt-md-5" id="collapsingNavbar">
                    <ul className="navbar-nav mr-auto ml-auto pt-md-1 ">
                      {/* <li className="nav-item">
                        <a className="nav-link" href="#">Buy An Odd Fellow </a>
                      </li>&nbsp; */}
                      <li className={`nav-item ${navActiveData === 1 ? 'active' : ''}`}>
                        <AnchorLink className="nav-link" data-toggle="" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" href="#header" onClick={e => navActive(1)}>Home</AnchorLink>
                      </li>&nbsp;
                      {/* <li className="nav-item">
                        <AnchorLink className="nav-link" data-toggle="" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" href="#Meet_Our_Bac">About</AnchorLink >
                      </li>&nbsp; */}
                      <li className={`nav-item ${navActiveData === 2 ? 'active' : ''}`}>
                        <AnchorLink className="nav-link" data-toggle="" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" href="#roadmap" onClick={e => navActive(2)}>Roadmap</AnchorLink>
                      </li>&nbsp;
                      <li className={`nav-item ${navActiveData === 3 ? 'active' : ''}`}>
                        <AnchorLink className="nav-link" data-toggle="" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" href="#team" onClick={e => navActive(3)}>Team</AnchorLink>
                      </li>&nbsp;
                      {/* <li className="nav-item">
                      <NavLink className="nav-link" to={`${config.baseUrl}Marketplace`}>Gallery </NavLink>
                      </li>&nbsp; */}
                      <li className={`nav-item ${navActiveData === 4 ? 'active' : ''}`}>
                        <AnchorLink className="nav-link" data-toggle="" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" href="#faq" onClick={e => navActive(4)}>FAQ</AnchorLink>
                      </li>
                      {/* <li class="nav-item">
                                 <a class="nav-link" href="#">Team</a>
                                 </li> */}
                      {/* <li> <a href="#" className='btn btn-primary mb-2 btn-wallet' id="wallet_section_first">Wallet</a></li> */}
                    </ul>
                    <ul className="navbar-nav ml-auto pt-md-1">
                      <li>
                        <div className="social-buttons lead-1">

                          {/* <a className="social-facebook" href="#" target="_blank"><i className="fa fa-wallet" /></a> */}
                          <a className="social-facebook" target="_blank" href="https://discord.gg/CAVRasc8"><img src="assets/img/Discord-Logo.png" width="25px" style={{ "filter": "brightness(0) invert(1)", "object-fit": "contain" }} /></a>
                          {/* <a className="social-facebook" href="#" target="_blank"><i className="fab fa-facebook" /></a> */}
                          <a className="social-twitter" target="_blank" href="https://twitter.com/BootyCoinNFT" ><i className="fab fa-twitter" /></a>
                          {/* <a className="social-instagram" href="#" target="_blank"><i className="fab fa-instagram" /></a> */}
                          {/* <a className="social-dribbble" href="#"><i className="fab fa-opensea"><img src="assets/img/ship.png" style={{width: '21px', marginTop: '-3px'}} /></i></a> */}
                        </div>
                      </li>
                      <li className="nav-item">

                        {ConnectWalletAddress ?
                          <a style={{ background: '#4a3232' }} href={`https://etherscan.io/address/${ConnectWalletAddress}`} target="_blank" className="nav-link btn-border" >
                            {ConnectWalletAddress.toString().substring(0, 5) + '...' + ConnectWalletAddress.toString().substr(ConnectWalletAddress.length - 5)}
                          </a>
                          :
                          <a style={{ background: '#4a3232' }} className="nav-link btn-border" onClick={connectMetaMask}>
                            Connect Wallet
                          </a>
                        }

                      </li>
                      {ConnectWalletAddress ?
                        <li className='nav-item'> <a className="social-sign-out" onClick={logout} href="#" data-placement="top" title="Disconnect Wallet"><i className="fa fa-sign-out-alt" /></a></li>
                        : ''}

                      <li classname="nav-item">
                        {localStorage.getItem('connectWalletAddress') ? <>
                          <button className='wallet-box' onClick={() => setState({ isPaneOpen: !state.isPaneOpen })}>
                            <img className="wallet_icon" src="assets/img/wallet-i.png" />
                          </button>
                        </> : ''}

                        <SlidingPane
                          className="some-custom-class"
                          overlayClassName="some-custom-overlay-class"
                          isOpen={state.isPaneOpen}
                          width="30%"
                          onRequestClose={() => {
                            setState({ isPaneOpen: false });
                          }}
                        >
                          <RewardMenu />
                        </SlidingPane>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;   